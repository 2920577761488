import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const LinerStyles = ({ header = "Choose Your Scene" }) => {
  return (
    <div className="pt-8 pb-12 text-center">
      <h2 className="text-center mb-4">{header}</h2>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 max-w-3xl mx-auto px-8">
        <Link
          className="grid gap-2"
          to="/window-well-liners-in-denver/?style=Brown%20Stone%20Liner"
        >
          <StaticImage
            alt="Brown stone window well liner"
            className="rounded-lg"
            src={"../../../static/images/liners/decorative-stone.jpg"}
          />
          <p>Brown Stone</p>
        </Link>
        <Link
          className="grid gap-2"
          to="/window-well-liners-in-denver/?style=Gray%20Stone%20Liner"
        >
          <StaticImage
            alt="Gray stone window well liner"
            className="rounded-lg"
            src={"../../../static/images/liners/grey-stone.jpg"}
          />
          <p>Gray Stone</p>
        </Link>
        <Link
          className="grid gap-2"
          to="/window-well-liners-in-denver/?style=Gray%20Brick%20Liner"
        >
          <StaticImage
            alt="Gray brick window well liner"
            className="rounded-lg"
            src={"../../../static/images/liners/grey-brick.jpg"}
          />
          <p>Gray Brick</p>
        </Link>
        <Link
          className="grid gap-2"
          to="/window-well-liners-in-denver/?style=White%20Brick%20Liner"
        >
          <StaticImage
            alt="White brick window well liner"
            className="rounded-lg"
            src={"../../../static/images/liners/white-brick.jpg"}
          />
          <p>White Brick</p>
        </Link>
      </div>
    </div>
  );
};
